import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import Page from '../../containers/DraggerGame/Result'
import Seo from '../../containers/Seo'
import plainlaw from '../../games/plainlaw.json'

const Game = (props) => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`)
  return (
    <>
      <Seo.Plainlaw />
      <Page
        {...props}
        d={plainlaw}
        data={data}
      />
    </>
  )
}

export default Game
